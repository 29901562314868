import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as constants from '../../constants/actionTypes';
import withAuthStyle from './withAuthStyle';
import {RouterPaths} from '../App/RouterPaths';
import LoadingIndicator from 'components/LoadingIndicator';
import TextBox from "components/common/TextBox";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../../applicationInsights";
import useTrackPageVisit from "../../components/common/hooks/useTrackPageVisit";
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {createStructuredSelector} from "reselect";
import * as selectors from "../App/selectors";
import LoadingPanel from "../../components/LoadingPanel";

const Login = (props) => {
  const { t } = useTranslation();
  useTrackPageVisit("Login");
  const navigate = useNavigate();


  const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [errMessages, setErrorMessages] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
      if(props.authState && props.authState.state === "loaded") {
        navigate('/');
      }
    }, [navigate, props.authState]);

    const isInputValid = (val) => {
        if(val === null) return true;
        if(val.length === 0) return false;
        return true;
    };

    const isFormValid = () => {
        return username && username.length > 0
        && password && password.length > 0;
    };

    const login = (event) => {
        event.preventDefault();
        setErrorMessages([]);
      if (isFormValid()) {
        setLoader(true);
        props.login(username, password, (isError, messages) => {
          setLoader(false);
          if(isError) {
            setErrorMessages(messages);
          } else {
            const redirectLocation = !localStorage.getItem('REDIRECT_ON_LOGIN') ? "/" : localStorage.getItem('REDIRECT_ON_LOGIN');
            navigate(redirectLocation);
            localStorage.removeItem('REDIRECT_ON_LOGIN');
          }
        });
      }
    };

    return (
      <LoadingPanel isLoading={props.authState && props.authState.state === "loaded"}>
        <form id="login-form" name="login-form" onSubmit={(e) => login(e)}>
        <div className="col-md-10 col-md-offset-6">
            <span className="title">Register or Sign in</span>
            <div className="login-input-group py-3 pt-5">
              <TextBox error={!isInputValid(username)}
                       variant="standard"
                       label="Username"
                       id="login-username"
                       name="login-username"
                       className="auth-inputs"
                       defaultValue={username || ''}
                       onChange={(e) => setUsername(e.target.value)}
                       autoComplete="username"/>
            </div>
            <div className="login-input-group py-3">
              <TextBox error={!isInputValid(password)}
                       variant="standard"
                       id="login-password"
                       name="login-password"
                       label="Password"
                       type="password"
                       className="auth-inputs"
                       defaultValue={password || ''}
                       onChange={e => setPassword(e.target.value)}
                       autoComplete="current-password"/>
            </div>
            <div className="pt-4 pb-3 row">
                <div className="col-3">
                    <input type="submit" id="login-btn-submit" name="login-btn-submit" className="btn btn-light login-btn float-start" required={true} value="Login" />
                </div>
                {loader && <div className="col-2 align-middle p-2">
                    <LoadingIndicator  />
                </div>}
            </div>
            {errMessages.length > 0 && errMessages.map(e => <div key={e} className="alert alert-danger" role="alert">{e}</div>)}
            <div className="auth-actions">
              <Link to={RouterPaths.FORGOTTENPASSWORD} className="btn btn-link">{t('LoginPage.ForgottenPassword.Link', 'Forgotten password')}</Link>
              <Link to={RouterPaths.REGISTER} className="btn btn-link">{t('LoginPage.Register.Link', 'Register')}</Link>
            </div>
        </div>
        </form>
      </LoadingPanel>
    );
};

Login.propTypes = {
  login: PropTypes.func,
  authState: PropTypes.object
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password, cb) => dispatch({type: constants.Auth.SAGA_LOGIN, username, password, cb})
    };
};

const mapStateToProps = createStructuredSelector({
  authState: selectors.selectAuth(),
});

export default compose(
    withAuthStyle,
    connect(mapStateToProps, mapDispatchToProps)
)(withAITracking(reactPlugin, Login, "Login"));
