import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import * as constants from 'constants/actionTypes';
import LoadingPanel from "components/LoadingPanel";

const CallbackPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id_token = params.get('id_token');

  useEffect(() => {
    if (id_token) {
      localStorage.setItem("isRedirectedFromCXPortal", "true");
      dispatch({
        type: constants.Auth.SAGA_LOGIN_CX_PORTAL,
        token: id_token
      });
    }
  }, [dispatch, id_token]);

  return(
    <LoadingPanel isLoading={true}/>
  );
};

export default React.memo(CallbackPage);
