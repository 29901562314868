import * as types from 'constants/actionTypes';
import { produce } from 'immer';

export const initialState = {
    error: null,
    auth: {
      userId: null,
      expiredAt: 0,
      state: 'init'
    }
};

const applicationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type){
        case types.Application.ERROR_UNAUTHORIZED:
            draft.error = {
                title: action.value.title,
                msg: action.value.message,
                error: action.value.error,
            };
            draft.logout = true;
            break;
        case types.Application.CLEAR_LOGOUT_FLAG:
            draft.logout = false;
            break;
        case types.Application.ERROR_RESET:
            draft.error = null;
            break;
        case types.Application.INFO_RESET:
            draft.info = null;
            break;
        case types.Auth.REDUX_LOGED_IN:
            draft.auth.userId = action.value.userId;
            draft.auth.expiresAt = action.value.expiresAt;
            draft.auth.state = action.value.state;
            break;
        case types.Auth.REDUX_LOG_OUT:
            draft.auth.userId = null;
            draft.auth.expiresAt = 0;
            draft.auth.state = 'out';
            break;
    }
  });

export default applicationReducer;
