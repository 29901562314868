import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import {CircularProgress} from '@mui/material';

const LoadingIndicator = () => {
  return (
    <Wrapper>
      <CircularProgress color="secondary" />
    </Wrapper>
  );
};

LoadingIndicator.propTypes = {
  type: PropTypes.string,
  properties: PropTypes.object,
  noWrapper: PropTypes.bool
};

export default LoadingIndicator;
