import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../LoadingIndicator/index';

const LoadingPanel = ({isLoading, children}) => {
    if (isLoading) {
        return (<LoadingIndicator />);
    } else {
        return (<>{children}</>);
    }
};

LoadingPanel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool])
};

export default LoadingPanel;
