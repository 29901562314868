import React from 'react';
import SwepLogo from 'styles/images/swep-logo.svg';
import DthemrxLogo from 'styles/images/DThermX-logo.png';
import'./loginStyle.scss';

const withAuthStyle = (AuthPage) => {
    const wrapper = props => {
        return (
            <div className="login-page ">
              <div className="login-left-panel">
                <div className="swep-logo-wrapper">
                  <img alt="SSP" className="ssp-web-logo img-fluid" src={SwepLogo} />
               </div>
                <div className="logo-area d-flex">
                  <span className="align-self-center logo-wrapper" >
                        <img alt="SSP" className="dthermx-logo img-fluid" src={DthemrxLogo} />
                    </span>
                </div>
              </div>
              <div className="login-right-panel px-3 pb-2">
                <div className="logo-wrapper">
                <img alt="SSP" className="dthermx-logo-s img-fluid" src={DthemrxLogo} />
                </div>

                <div className="login-form">
                        <AuthPage {...props} />
                    </div>
                </div>
            </div>
        );
    };
    return wrapper;
};

export default withAuthStyle;
