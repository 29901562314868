import React, {useEffect, useRef} from 'react';
import LoadingPanel from "../../../components/LoadingPanel";
import {useQuery} from "../../../utils/helpers";
import useTrackPageVisit from "../../../components/common/hooks/useTrackPageVisit";
const LoginPage = () => {
  useTrackPageVisit("Login ISH");
  const ref = useRef();
  const query = useQuery();

  useEffect(() => {
    const redirectURL = query.get("url");

    if (redirectURL) {
      localStorage.setItem('redirectURL', redirectURL);
    } else {
      localStorage.removeItem('redirectUrl');
    }
  }, [query]);

  useEffect(() => {
    if (ref.current) {
      ref.current.submit();
    }
  }, []);

  return (
    <div>
      <form ref={ref} action={`${process.env.DATAHALL_DIRECT}/login-cxportal`} method="POST" style={{ display: 'hidden' }}>
        <input type="submit" value="Login CX"/>
      </form>
      <LoadingPanel isLoading={true}/>
    </div>
  );
};

export default React.memo(LoginPage);
