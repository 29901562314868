import {
  LATEST_NEWS_LOAD,
  LATEST_NEWS_LOAD_SUCCESS,
  LATEST_NEWS_LOAD_ERROR,
  CAROUSEL_ITEMS_LOAD,
  CAROUSEL_ITEMS_LOAD_SUCCESS,
  CAROUSEL_ITEMS_LOAD_ERROR,
  WIKI_GET_PAGE
} from '../constants/actionTypes';

export function loadNews() {
  return {
    type: LATEST_NEWS_LOAD,
  };
}

export function loadNewsSuccess(news) {
  return {
    type: LATEST_NEWS_LOAD_SUCCESS,
    news: news
  };
}

export function loadNewsError(error) {
  return {
    type: LATEST_NEWS_LOAD_ERROR,
    error: error
  };
}

export function loadCarouselItems() {
  return {
    type: CAROUSEL_ITEMS_LOAD
  };
}

export function loadCarouselItemsSuccess(items) {
  return {
    type: CAROUSEL_ITEMS_LOAD_SUCCESS,
    items: items
  };
}

export function loadCarouselItemsError(error) {
  return {
    type: CAROUSEL_ITEMS_LOAD_ERROR,
    error: error
  };
}

export function getWikiPage(pageId) {
  return {
    type: WIKI_GET_PAGE,
    pageId: pageId,
  };
}

export function processWikiRsponse(response) {
  if(response){
    const page = response.data.pages.single.render;
    const wikiDoc = new DOMParser().parseFromString(page, 'text/html');
    let wikiObject = {};
    let lastId = '';
    wikiDoc.body.children.forEach(x => {
      if(x.tagName.startsWith('H')){
        lastId = x.id;
        wikiObject[lastId]= {
          title: x.innerText.replace('¶',''),
          texts: []
        };
      } else {
        wikiObject[lastId].texts.push(x.innerText);
      }
    });
    return wikiObject;
  }
  return '';
}
