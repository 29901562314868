import React, {useContext, useEffect} from "react";
import PropTypes from 'prop-types';
import UnitSystemStateContext from './UnitSystemStateContext';
import UnitSystemDispatchContext from './UnitSystemDispatchContext';
import UnitSystemContext from "../../../common/UnitSystems/UnitSystemContext";

const UnitSystemProvider = ({ children }) => {
    const unitSystemGlobal = useContext(UnitSystemContext);
    const [unitSystem, setUnitSystem] = React.useState("Metric");

    useEffect(() => {
      setUnitSystem(unitSystemGlobal);
    }, [unitSystemGlobal]);

    return (
        <UnitSystemStateContext.Provider value={unitSystem}>
            <UnitSystemDispatchContext.Provider value={setUnitSystem}>
                {children}
            </UnitSystemDispatchContext.Provider>
        </UnitSystemStateContext.Provider>
    );
};

UnitSystemProvider.propTypes = {
  children: PropTypes.node
};

export default UnitSystemProvider;
