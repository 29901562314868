import { useLocation } from "react-router-dom";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function removeSpaces(value) {
  if(!value) {
    return value;
  }
  return value.replace(/\s/g, '');
}
