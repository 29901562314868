import React from 'react';
import { withBaseIcon } from 'react-icons-kit';
import {ic_menu} from 'react-icons-kit/md/ic_menu';
import {ic_person} from 'react-icons-kit/md/ic_person';
import {ic_view_headline} from 'react-icons-kit/md/ic_view_headline';
import {ic_view_module} from 'react-icons-kit/md/ic_view_module';
import {plus} from 'react-icons-kit/fa/plus';
import {minus} from 'react-icons-kit/fa/minus';
import {ic_delete} from 'react-icons-kit/md/ic_delete';
import {plus as plusCircle } from 'react-icons-kit/metrize/plus';
import {layers} from 'react-icons-kit/metrize/layers';
import {blank} from 'react-icons-kit/metrize/blank';
import {rec} from 'react-icons-kit/metrize/rec';
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down';
import {ic_keyboard_arrow_up} from 'react-icons-kit/md/ic_keyboard_arrow_up';
import {ic_blur_on} from 'react-icons-kit/md/ic_blur_on';
import {ic_shopping_basket} from 'react-icons-kit/md/ic_shopping_basket';
import {ic_settings} from 'react-icons-kit/md/ic_settings';
import {ic_home} from 'react-icons-kit/md/ic_home';
import {ic_navigate_before} from 'react-icons-kit/md/ic_navigate_before';
import {ic_edit} from 'react-icons-kit/md/ic_edit';
import {ic_open_in_new} from 'react-icons-kit/md/ic_open_in_new';
import {phone} from 'react-icons-kit/fa/phone';
import {fax} from 'react-icons-kit/fa/fax';
import {envelope} from 'react-icons-kit/fa/envelope';
import {ic_rate_review} from 'react-icons-kit/md/ic_rate_review';
import {globe} from 'react-icons-kit/fa/globe';
import {calendar} from 'react-icons-kit/fa/calendar';
import {hammer} from 'react-icons-kit/ionicons/hammer';
import {ic_close} from 'react-icons-kit/md/ic_close';
import {lab} from 'react-icons-kit/icomoon/lab';
import {clone} from 'react-icons-kit/fa/clone';
import {ic_blur_linear} from 'react-icons-kit/md/ic_blur_linear';
import {beaker} from 'react-icons-kit/iconic/beaker';

//check https://github.com/wmira/react-icons-kit for icons

const SideIconContainer = withBaseIcon();


export const IconBurgerMenu = (props) => <SideIconContainer icon={ic_menu} {...props} />; // eslint-disable-line
export const IconUserMenu = (props) => <SideIconContainer icon={ic_person} {...props} />; // eslint-disable-line
export const IconTableView = (props) => <SideIconContainer icon={ic_view_headline} {...props} />; // eslint-disable-line
export const IconCardView = (props) => <SideIconContainer icon={ic_view_module} {...props} />; // eslint-disable-line
export const IconPlus = (props) => <SideIconContainer icon={plus} {...props} />; // eslint-disable-line
export const IconMinus = (props) => <SideIconContainer icon={minus} {...props} />; // eslint-disable-line
export const IconBin = (props) => <SideIconContainer icon={ic_delete} {...props} />; // eslint-disable-line
export const IconPlusCircle = (props) => <SideIconContainer icon={plusCircle} {...props} />; // eslint-disable-line
export const IconCircleLayers = (props) => <SideIconContainer icon={layers} {...props} />; // eslint-disable-line
export const IconCircleEmpty = (props) => <SideIconContainer icon={blank} {...props} />; // eslint-disable-line
export const IconCircleWithDot = (props) => <SideIconContainer icon={rec} {...props} />; // eslint-disable-line
export const IconArrowDown = (props) => <SideIconContainer icon={ic_keyboard_arrow_down} {...props} />; // eslint-disable-line
export const IconArrowUp = (props) => <SideIconContainer icon={ic_keyboard_arrow_up} {...props} />; // eslint-disable-line
export const IconBlur = (props) => <SideIconContainer icon={ic_blur_on} {...props} />; // eslint-disable-line
export const IconBasket = (props) => <SideIconContainer icon={ic_shopping_basket} {...props} />; // eslint-disable-line
export const IconSettings = (props) => <SideIconContainer icon={ic_settings} {...props} />; // eslint-disable-line
export const IconHome = (props) => <SideIconContainer icon={ic_home} {...props} />; // eslint-disable-line
export const IconArrowLeft = (props) => <SideIconContainer icon={ic_navigate_before} {...props} />; // eslint-disable-line
export const IconEdit = (props) => <SideIconContainer icon={ic_edit} {...props} />; // eslint-disable-line
export const IconOpenInNew = (props) => <SideIconContainer icon={ic_open_in_new} {...props} />; // eslint-disable-line
export const IconPhone = (props) => <SideIconContainer icon={phone} {...props} />; // eslint-disable-line
export const IconFax = (props) => <SideIconContainer icon={fax} {...props} />; // eslint-disable-line
export const IconEnvelope = (props) => <SideIconContainer icon={envelope} {...props} />; // eslint-disable-line
export const IconFeedback = (props) => <SideIconContainer icon={ic_rate_review} {...props} />; // eslint-disable-line
export const IconGlobe = (props) => <SideIconContainer icon={globe} {...props} />; // eslint-disable-line
export const IconCalendar = (props) => <SideIconContainer icon={calendar} {...props} />; // eslint-disable-line
export const IconHammer = (props) => <SideIconContainer icon={hammer} {...props} />; // eslint-disable-line
export const IconClose = (props) => <SideIconContainer icon={ic_close} {...props} />; // eslint-disable-line
export const IconLab = (props) => <SideIconContainer icon={lab} {...props} />; // eslint-disable-line
export const IconClone = (props) => <SideIconContainer icon={clone} {...props} />; // eslint-disable-line
export const IconMulticalc = (props) => <SideIconContainer icon={ic_blur_linear} {...props} />; // eslint-disable-line
export const FluidIcon = (props) => <SideIconContainer icon={beaker} {...props} />; // eslint-disable-line
