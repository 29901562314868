import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectAuth } from "containers/App/selectors";
import * as appActions from "actions/applicationActions";
import {makeSelectUserAllowedCalculations} from 'selectors/userProfileSelectors';
import { AllCalculationTypes } from 'constants/calculationTypes';
import {RouterPaths} from '../containers/App/RouterPaths';

const PrivateRoute = ({ authState, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname.toLowerCase();

    if(!rest.allowedCalculations.some(x => `/${x.toLowerCase()}` === currentPath)
      && AllCalculationTypes.some(x => `/${x.toLowerCase()}` === currentPath)) {
      <Navigate to="/notfound"/>;
    }
  }, [rest.allowedCalculations, location.pathname]);

  useEffect(() => {
    if((authState.state === 'out')
      && !appActions.isAuthenticated(authState)){
      localStorage.setItem('REDIRECT_ON_LOGIN', location.pathname);
      navigate(RouterPaths.LOGIN);
    }
  }, [authState, location.pathname, navigate]);

  return <Outlet />;
};

PrivateRoute.propTypes = {
  authState: PropTypes.object,
  allowedCalculations: PropTypes.array
};

const mapStateToProps = createStructuredSelector({
    authState: selectAuth(),
    allowedCalculations: makeSelectUserAllowedCalculations()
});

const withConnect = connect(mapStateToProps,null);

export default compose(withConnect, memo)(PrivateRoute);
