import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose} from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectCalculations } from 'selectors/calculationSelectors';
import * as calculationActions from 'actions/calculationActions';
import './inputs.scss';
import get from 'lodash/get';
import CalculationInputsBase from "./CalculationInputsBase";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {RouterPaths} from "../../../containers/App/RouterPaths";

const CalculationInputs = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const {calculationType, calculation, calcActions} = props;
  const {changeCalculationInputFields} = calcActions;
  const calculationData = get(calculation, `[${calculationType}].inputParams`, null);
  const loadingInputs = get(calculation, `[${calculationType}].loading`, true);

  const handleCalcMethodChange = useCallback((value) => {
    changeCalculationInputFields(calculationType, [{fieldName: "calculationMethod", value: value}]);
    const {calcId} = params;
    const newParams = {
      calculation: value,
      calcId: calcId || '',
    };
    const newPath = generatePath(RouterPaths[calculationType.toUpperCase()], newParams);
    navigate(newPath);
  }, [changeCalculationInputFields, calculationType, params, navigate]);

  return (
    <CalculationInputsBase
      calculationType={props.calculationType}
      userId={props.userId}
      calculationData={calculationData}
      loadingInputs={loadingInputs}
      onChangeInputs={props.calcActions.changeCalculationInputFields}
      onSetInputsValidity={props.calcActions.setInputFormValidity}
      loadCalculationInputsHeatExchangers={props.calcActions.loadCalculationInputsHeatExchangers}
      handleOnMethodChange={handleCalcMethodChange}
    />
  );
};

CalculationInputs.propTypes = {
    calculationType: PropTypes.string.isRequired,
    calcActions: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    calculation: PropTypes.object
};

const mapStateToProps = () => {
    return createStructuredSelector({
        calculation: makeSelectCalculations(),
    });
};

const mapDispatchToProps = (dispatch) => {
    return {
      calcActions: bindActionCreators(calculationActions, dispatch),
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  // withRouter,
  withConnect,
  memo
)(CalculationInputs);
