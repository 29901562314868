import React, {memo} from 'react';
import PropTypes from "prop-types";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import { makeSelectCalculationInputParamsFilterSettings } from 'selectors/calculationSelectors';
import * as calculationActions from 'actions/calculationActions';
import FilterInputsBase from "./FilterInputsBase";

const FilterInputs = ({ calculationType, actions, filterSettings }) => {

  return (<FilterInputsBase
      calculationType={calculationType}
      changeFilters={actions.changeFilters}
      filterSettings={filterSettings}
  />);
};

FilterInputs.propTypes = {
  calculationType: PropTypes.string.isRequired,
  actions: PropTypes.object,
  filterSettings: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    filterSettings: makeSelectCalculationInputParamsFilterSettings(ownProps.calculationType)(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(calculationActions, dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FilterInputs);
