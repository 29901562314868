import React, { Suspense } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import {ErrorBoundary} from 'react-error-boundary';
import GeneralFallback from '../../../../Errors/GeneralFallback';
import LogToApplicationInsights from '../../../../Errors/logToApplicationInsights';

const LazyComponent = React.lazy(() => import('./TDSinglePhase'));

const Loadable = props => {
  return (
    <ErrorBoundary FallbackComponent={GeneralFallback} onError={LogToApplicationInsights}>
      <Suspense fallback={<LoadingIndicator />}>
        <LazyComponent {...props}/>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Loadable;
