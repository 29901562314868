import {all, takeLatest, take, call, select, put} from 'redux-saga/effects';
import {
  CALCULATION_LOAD,
  CALCULATION_CLONE,
  CALCULATION_XREF_LOAD,
  CALCULATION_RELOAD_HEAT_EXCHANGERS,
  CALCULATION_CALCULATE,
  CALCULATION_SELECTED_RESULT_PRODUCT_SHEET_DOWNLOAD,
  CALCULATION_INPUTS_SAVE_TO_MY_CALCULATIONS,
  CALCULATION_INPUTS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE,
  CALCULATION_INPUTS_RESET_DEFAULT_FOR_CALCULATION_TYPE,
  CALCULATION_INPUTS_RESET_DEFAULT_FOR_ALL_CALCULATIONS,
  CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE,
  CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_CALCULATION_TYPE,
  CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_ALL_CALCULATIONS,
  CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_ALL_CALCULATIONS,
  CALCULATION_FROM_BASKET_LOAD,
} from './constants';
import {
  loadCalculationInputsError,
  loadCalculationInputsSuccess,
  reloadCalculationHeatExchangersSuccess,
  reloadCalculationHeatExchangersError,
  calculateSuccess,
  calculateError,
  loadCalculationFromBasketSuccess,
  addCalculation,
  loadCalculationInputs
} from './actions';
import exchangersApi from '../../api/exchangersApi';
import CalculationsApi from '../../api/calculationsApi';
import {
  makeSelectUserId,
  makeSelectUserProfile
} from '../../selectors/userProfileSelectors';
import {
  makeSelectCalculation
} from './selector';
import {processCalculationResponse} from '../../sagas/calculationSaga';
import {selectWorkingLanguage} from '../App/selectors';
import {enqueueSnackbar} from '../Notifier/actions';
import {loadCalculation, saveCalculation} from './DexieJsDBApi';
import {uuidv4} from '../../utils/uuid';
import {produce} from 'immer';
import {SinglePhase} from '../../constants/calculationTypes';
import {createNewCalculation} from './reducer';
import {generateCalculationPath} from '../App/RouterPaths';
import {appInsights} from '../../applicationInsights';

export function* createOrLoadCalculationInputs(action) {
  try {
    // console.log("createOrLoadCalculationInputs");
    const userId = yield getOrWaitForUserId();
    const calculationInDaState = yield select(makeSelectCalculation(action.uuid));

    if (calculationInDaState) {
      // console.log("calculation already in state", calculationInDaState);
      return;
    }

    const bdCalculation = yield call(loadCalculation, action.uuid);
    if (bdCalculation) {
      yield put(addCalculation(bdCalculation));
      return;
    }

    yield put(loadCalculationInputs(action.calculationType, action.uuid));
    const {data} = yield call(CalculationsApi.getCalculationInputs, userId, action.calculationType, action.saved === 'saved' ? action.uuid : null);
    yield put(loadCalculationInputsSuccess(action.calculationType, action.uuid, data));
  } catch (e) {
    yield put(loadCalculationInputsError(action.uuid, e.message));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'createOrLoadCalculationInputs error',
        calculationType: action.calculationType,
        uuid: action.uuid,
        userId: yield select(makeSelectUserId()),
      },
    });
  }
}

export function* cloneCalculation(action) {
  const calculation = yield select(makeSelectCalculation(action.uuid));
  const calculationClone = produce(calculation, draft => {
    draft.uuid = uuidv4();
  });

  const calculationType = calculationClone.inputParams.userCalculationInputs.calculationType.toLowerCase();
  yield call(saveCalculation, calculationClone);
  const calculationPath = generateCalculationPath({
    calculationType: calculationType,
    uuid: calculationClone.uuid
  });
  window.open(calculationPath, "_blank");
}

export function* loadCalculationFromXRef(action) {
  const userId = yield getOrWaitForUserId();

  const uuid = uuidv4();
  const {data} = yield call(CalculationsApi.getCalculationInputs, userId, SinglePhase);

  const x = [];
  action.heatExchangerNames.forEach(heatExchangerName => {
    const y = data.availableHeatExchangers.filter(x => x.isLeaf === true).find(x => x.name.toLowerCase() === heatExchangerName.toLowerCase());
    if (y) {
      x.push({
        id: y.id,
        name: y.name,
        heatExchangerInputType: "Child"
      });
    }
  });

  const newCalculation = createNewCalculation(uuid);
  data.userCalculationInputs.selectedHeatExchangers = x;
  newCalculation.inputParams = data;
  yield call(saveCalculation, newCalculation);
  const calculationPath = generateCalculationPath({
    calculationType: SinglePhase.toLowerCase(),
    uuid: uuid
  });
  window.open(calculationPath, "_blank");
}

export function* reloadHeatExchangers(action) {
  try {
    const userId = yield getOrWaitForUserId();
    const {data} = yield call(exchangersApi.getExchangers, userId, action.modelRestrictions, action.coCurrent, action.selectedFluidSide1, action.selectedFluidSide2);
    yield put(reloadCalculationHeatExchangersSuccess(action.uuid, data));
  } catch (e) {
    yield put(reloadCalculationHeatExchangersError(action.uuid, e.message));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'reloadHeatExchangers error',
        userId: yield select(makeSelectUserId()),
        modelRestrictions: action.modelRestrictions,
        coCurrent: action.coCurrent,
        selectedFluidSide1: action.selectedFluidSide1,
        selectedFluidSide2: action.selectedFluidSide2,
      },
    });
  }
}

const createRequest = (calculation, userProfile) => {
  let request = {
    calculationData: {
      applicationName: calculation.inputParams.userCalculationInputs.calculationType,
      calculationType: calculation.inputParams.userCalculationInputs.calculationMethod,
      inputValues: [],
      userCalculationInputs: calculation.inputParams.userCalculationInputs
    },
    userDetails: {
      userId: userProfile.userId,
      unit: userProfile.units
    },
    strLang: userProfile.applicationLanguage,
    nosResults: "7",
    bCulture: "false",
    unitSystem: userProfile.settings.units
  };
  //set filters
  request.userSetfilter = {
    factoriesFilterSelection: calculation.inputParams.filterSettings.factorySelections,
    materialsFilterSelection: calculation.inputParams.filterSettings.materialSelections,
    pressuresFilterSelection: calculation.inputParams.filterSettings.pressureSelections,
    warehousesFilterSelection: calculation.inputParams.filterSettings.warehouseSelections,
    showOnStockItemsOnly: calculation.inputParams.filterSettings.showOnStockItemsOnly
  };
  return request;
};


export function* calculate(action) {
  try {
    const calculation = yield select(makeSelectCalculation(action.uuid));
    const userProfile = yield select(makeSelectUserProfile());
    const req = createRequest(calculation, userProfile);
    const {data} = yield call(CalculationsApi.calculate, req);
    const res = processCalculationResponse(data);
    yield put(calculateSuccess(action.uuid, {...res, inputParams: calculation.inputParams}));
  } catch (e) {
    yield put(calculateError(action.uuid, e.message));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'calculate error',
        userId: yield select(makeSelectUserId()),
      },
    });
  }
}

function* getOrWaitForUserId() {
  while (true) {
    const userId = yield select(makeSelectUserId());
    if (userId !== undefined && userId !== null && userId !== "") {
      return userId;
    }
    yield take('*');
  }
}

export function* downloadProductSheet(action) {
  try {
    const {data} = yield call(exchangersApi.getProductNumber, action.xpc);
    const workingLanguage = yield select(selectWorkingLanguage());
    window.open(`https://sweppim.azurewebsites.net/pdf/GetProductSheet?productnumber=${data.productNumber}&languageCode=${workingLanguage || 'en'}`, "_blank", "noreferrer");
  } catch (e) {
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Unable to download product sheet',
        xpc: action.xpc,
        workingLanguage: yield select(selectWorkingLanguage()),
      },
    });
  }
}

export function* saveCalculationInputsToMyCalculations(action) {
  try {
    const userId = yield getOrWaitForUserId();
    const calculation = yield select(makeSelectCalculation(action.uuid));
    const userCalculationInputs = calculation.inputParams.userCalculationInputs;
    const calculationFilters = calculation.inputParams.filterSettings;

    const asdf = {
      id: null,
      userId: userId,
      customName: action.customName,
      createdOn: new Date(),
      note: action.note,
      tags: action.tags,
      userCalculationInputs: userCalculationInputs,
      filterSettings: calculationFilters
    };
    const saveCalculationInputsResponse = yield call(CalculationsApi.saveCalculationInputs, asdf);
    yield put(enqueueSnackbar({message: "Saved!", options: {variant: "success"}}));

    if (action.callback) {
      yield call(action.callback, saveCalculationInputsResponse.data);
    }
  } catch (e) {
    yield put(enqueueSnackbar({message: "An error occurred during saving inputs", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Unable to save calculation inputs to my calculations',
        userId: yield select(makeSelectUserId()),
      },
    });
  }
}

export function* saveCalculationInputsAsDefaultForCalculationType(action) {
  try {
    const userId = yield getOrWaitForUserId();
    const calculation = yield select(makeSelectCalculation(action.uuid));
    const userCalculationInputs = calculation.inputParams.userCalculationInputs;
    const calculationType = userCalculationInputs.calculationType;
    yield call(CalculationsApi.saveDefaultCalculationInputs, userId, calculationType, userCalculationInputs);
    yield put(enqueueSnackbar({message: "Saved!", options: {variant: "success"}}));
  } catch (e) {
    yield put(enqueueSnackbar({message: "An error occurred during saving inputs", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Unable to save calculation inputs as default for calculation type',
        userId: yield select(makeSelectUserId()),
      },
    });
  }
}

export function* resetDefaultCalculationInputsForCalculationType(action) {
  const userId = yield getOrWaitForUserId();
  const calculation = yield select(makeSelectCalculation(action.uuid));
  const calculationType = calculation.inputParams.userCalculationInputs.calculationType;
  try {
    yield call(CalculationsApi.deleteDefaultCalculationTypeCalculationInputs, userId, calculationType);
    const {data} = yield call(CalculationsApi.getCalculationInputs, userId, calculationType);
    yield put(loadCalculationInputsSuccess(calculationType, action.uuid, data));
    yield put(enqueueSnackbar({message: "Reseted!", options: {variant: "success"}}));
  } catch (e) {
    yield put(enqueueSnackbar({message: "An error occurred during saving inputs", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Unable to reset default calculation inputs for calculation type',
        userId: yield select(makeSelectUserId()),
      },
    });
  }
}

export function* resetDefaultCalculationsForAllCalculations(action) {
  try {
    const userId = yield getOrWaitForUserId();
    const calculation = yield select(makeSelectCalculation(action.uuid));
    const calculationType = calculation.inputParams.userCalculationInputs.calculationType;
    yield call(CalculationsApi.deleteAllDefaultCalculationInputs, userId);
    const {data} = yield call(CalculationsApi.getCalculationInputs, userId, calculationType);
    yield put(loadCalculationInputsSuccess(calculationType, action.uuid, data));
    yield put(enqueueSnackbar({message: "Reseted!", options: {variant: "success"}}));
  } catch (e) {
    yield put(enqueueSnackbar({message: "An error occurred during saving inputs", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Reset default calculations for all calculations error',
      },
    });
  }
}

export function* saveCalculationFiltersAsDefaultForCalculationType(action) {
  try {
    const userId = yield getOrWaitForUserId();
    const calculation = yield select(makeSelectCalculation(action.uuid));
    const calculationType = calculation.inputParams.userCalculationInputs.calculationType;
    const filters = calculation.inputParams.filterSettings;
    yield call(CalculationsApi.saveFilterSettings, userId, filters, calculationType);
    yield put(enqueueSnackbar({message: "Saved!", options: {variant: "success"}}));
  } catch (e) {
    yield put(enqueueSnackbar({message: "An error occurred during saving filters", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Save calculation filters as default for calculation type error',
        userId: yield select(makeSelectUserId()),
      },
    });
  }
}

export function* resetCalculationFiltersToDefaultForCalculationType(action) {
  try {
    const userId = yield getOrWaitForUserId();
    const calculation = yield select(makeSelectCalculation(action.uuid));
    const calculationType = calculation.inputParams.userCalculationInputs.calculationType;
    yield call(CalculationsApi.resetFilterSettings, userId, calculationType);
    const {data} = yield call(CalculationsApi.getCalculationInputs, userId, calculationType);
    yield put(loadCalculationInputsSuccess(calculationType, action.uuid, data));
    yield put(enqueueSnackbar({message: "Reseted!", options: {variant: "success"}}));
  } catch (e) {
    yield put(enqueueSnackbar({message: "An error occurred while resetting filters", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Reset calculation filters to default for calculation type error',
      },
    });
  }
}

export function* saveCalculationFiltersAsDefaultForAllCalculations(action) {
  try {
    const userId = yield getOrWaitForUserId();
    const calculation = yield select(makeSelectCalculation(action.uuid));
    const calculationFilters = calculation.inputParams.filterSettings;
    yield call(CalculationsApi.saveFilterSettings, userId, calculationFilters, null);
    yield put(enqueueSnackbar({message: "Saved!", options: {variant: "success"}}));
  } catch (e) {
    yield put(enqueueSnackbar({message: "An error occurred while saving filters", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Save calculation filters as default for all calculations error',
      },
    });
  }
}

export function* resetCalculationFiltersToDefaultForAllCalculations(action) {
  try {
    const userId = yield getOrWaitForUserId();
    const calculation = yield select(makeSelectCalculation(action.uuid));
    const calculationType = calculation.inputParams.userCalculationInputs.calculationType;
    yield call(CalculationsApi.resetFilterSettings, userId, null);
    const {data} = yield call(CalculationsApi.getCalculationInputs, userId, calculationType);
    yield put(loadCalculationInputsSuccess(calculationType, action.uuid, data));
    yield put(enqueueSnackbar({message: "Reseted!", options: {variant: "success"}}));
  } catch (e) {
    yield put(enqueueSnackbar({message: "An error occurred while saving filters", options: {variant: "error"}}));
    appInsights.trackException({
      exception: e,
      properties: {
        message: 'Reset calculation filters to default for all calculations error',
      },
    });
  }
}

export function* loadBasketInputs(action) {
  yield put(loadCalculationFromBasketSuccess(action.uuid, action.basketCalculation));
  yield call(action.callback);
}

export default function* () {
  yield all([
    takeLatest(CALCULATION_LOAD, createOrLoadCalculationInputs),
    takeLatest(CALCULATION_CLONE, cloneCalculation),
    takeLatest(CALCULATION_XREF_LOAD, loadCalculationFromXRef),
    takeLatest(CALCULATION_RELOAD_HEAT_EXCHANGERS, reloadHeatExchangers),
    takeLatest(CALCULATION_CALCULATE, calculate),
    takeLatest(CALCULATION_SELECTED_RESULT_PRODUCT_SHEET_DOWNLOAD, downloadProductSheet),
    takeLatest(CALCULATION_INPUTS_SAVE_TO_MY_CALCULATIONS, saveCalculationInputsToMyCalculations),
    takeLatest(CALCULATION_INPUTS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE, saveCalculationInputsAsDefaultForCalculationType),
    takeLatest(CALCULATION_INPUTS_RESET_DEFAULT_FOR_CALCULATION_TYPE, resetDefaultCalculationInputsForCalculationType),
    takeLatest(CALCULATION_INPUTS_RESET_DEFAULT_FOR_ALL_CALCULATIONS, resetDefaultCalculationsForAllCalculations),
    takeLatest(CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE, saveCalculationFiltersAsDefaultForCalculationType),
    takeLatest(CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_CALCULATION_TYPE, resetCalculationFiltersToDefaultForCalculationType),
    takeLatest(CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_ALL_CALCULATIONS, saveCalculationFiltersAsDefaultForAllCalculations),
    takeLatest(CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_ALL_CALCULATIONS, resetCalculationFiltersToDefaultForAllCalculations),
    takeLatest(CALCULATION_FROM_BASKET_LOAD, loadBasketInputs)
  ]);
}
