import React from 'react';
import { Link } from 'react-router-dom';
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../applicationInsights";
import useTrackPageVisit from "./common/hooks/useTrackPageVisit";

const NotFoundPage = () => {
  useTrackPageVisit("Not found page");
  return (
    <div>
      <h4>
        404 Page Not Found
      </h4>
      <Link to="/"> Go back to homepage </Link>
    </div>
  );
};

export default withAITracking(reactPlugin, NotFoundPage, "NotFoundPage");
