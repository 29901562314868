import React, { useState, memo, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import TreeSelect, {listToTree} from 'components/common/TreeSelect/TreeSelect';
import './filters.scss';

const FilterSelectDropDown = ({ treeData, value, onChange }) => {
  const [dataTree, setDataTree] = useState([]);
  // const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    //if (!isLoaded) {
      const tempData = treeData.map(x => {
        const initialCheck = value && value.some(v => v.key === x.key && v.isSelected) ? {checked: true} : {};
        return {
          ...initialCheck,
          key: x.key.toString(),
          parentId: x.group ?? 0,
          value: x.key.toString(),
          label: x.description,
          expanded: true,
          children: [],
          id: x.key
        };
      });
      setDataTree(listToTree(tempData));
      //setIsLoaded(true);
    //}
  }, [treeData, value]);

    const handleChange = useCallback((selected) => {
      let selectedKeys = [];
      const getSelectedKeys = (items) => {
        items.forEach(x => {
          if (x._children && x._children.length > 0) {
            getSelectedKeys(x._children);
          }
          else {
            selectedKeys.push(x.key ?? x);
          }
        });
      };
      getSelectedKeys(selected);

      let selections = [];
      const getSelFromTree = (dt, allChecked) => {
        dt.forEach(d => {
          if (d.children.length > 0) {
            if (selectedKeys.includes(d.key) || allChecked) {
              getSelFromTree(d.children, true);
            } else {
              getSelFromTree(d.children, false);
            }
          } else {
            selections.push({key: d.key, isSelected: selectedKeys.includes(d.key) || allChecked});
          }
        });
      };
      getSelFromTree([...dataTree]);
      onChange(selections);
    },[dataTree, onChange]);

    // console.log('%c filter tree', 'color:orange', treeData, value, '\n', dataTree)

    return (
      <div>
      <TreeSelect data={dataTree}
                  className="dtherm-ddl-select"
                  placeholder=" "
                  mode="multi"
                  inlineSearchInput={true}
                  onChange={handleChange}
                  showPartiallySelected={true}
      />
      </div>
    );
};

FilterSelectDropDown.propTypes = {
    treeData: PropTypes.array.isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired
};

export default memo(FilterSelectDropDown);
