import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Collapse, IconButton} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from "clsx";

const ExpandablePanel = ({ children, defaultIsExpanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);


  return (
    <div className="d-flex flex-column">
      <span className="d-inline-flex justify-content-center">
        <IconButton
          className={clsx( {'d-none': isExpanded })}
          aria-label="expand"
          onClick={() => setIsExpanded(true)}
          size="large">
          <ExpandMoreIcon/>
        </IconButton>
      </span>
      <Collapse in={isExpanded}>
        {children}
      </Collapse>
      <span className="d-inline-flex justify-content-center">
        <IconButton
          className={clsx({'d-none': !isExpanded })}
          aria-label="shrink"
          onClick={() => setIsExpanded(false)}
          size="large">
          <ExpandLessIcon/>
        </IconButton>
      </span>
    </div>
  );
};

ExpandablePanel.propTypes = {
  children: PropTypes.node,
  defaultIsExpanded: PropTypes.bool
};

export default ExpandablePanel;
