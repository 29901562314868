import applicationInsights from "../../../applicationInsights";
import {useEffect} from "react";

const useTrackPageVisit = (pageName) => {

  useEffect(() => {
    const host = location.host;
    const pathname = location.pathname;

    applicationInsights.startTrackPage(pageName);

    return () => {
      applicationInsights.stopTrackPage(pageName, pathname, {
        host: host,
        pageName: pageName,
        logger: 'useTrackPageVisit'
      });
    };
  }, [pageName]);
};

export default useTrackPageVisit;
