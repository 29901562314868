import React, { useEffect } from 'react';
import LoadingPanel from "../../../components/LoadingPanel";
import { useQuery } from "../../../utils/helpers";
import useTrackPageVisit from "../../../components/common/hooks/useTrackPageVisit";

const LogoutPage = () => {
  useTrackPageVisit("Logout");
  const query = useQuery();

  useEffect(() => {
    localStorage.removeItem('idtoken');
    localStorage.removeItem("isRedirectedFromCXPortal");
    const redirectLogoutUrl = query.get("redirect_logout_url");
    if (redirectLogoutUrl) {
      window.location.href = redirectLogoutUrl;
    }
  }, [query]);

  return (
    <div>
      <LoadingPanel isLoading={true}/>
    </div>
  );
};

export default React.memo(LogoutPage);
