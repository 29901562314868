import { produce } from 'immer';
import initialState from './initialState';
import * as types from 'constants/actionTypes';

const connectionsReducer = (state = initialState.connections, action) =>
    produce(state, draft => {

        switch(action.type){
            case types.Connections.TOGGLE_MODAL:{
                draft.isOpen = action.isOpen;
                if(action.isOpen){
                    draft.calculationType = action.calculationType;
                    draft.initialPortConfig = action.initialPortConfig;
                    draft.initialHeight = action.initialHeight;
                    draft.selectedArticle = action.selectedArticle;
                } else {
                    draft.calculationType = "";
                    draft.initialPortConfig = "";
                    draft.initialHeight = '';
                    draft.selectedArticle = null;
                    draft.connectionsData = [];
                }
                return draft;
            }
            case types.Connections.CONNECTIONS_FILTERS_LOAD:{
                draft.filters = action.value;
                return draft;
            }
            case types.Connections.FILTER_LOADING_TOGGLE:{
                draft.loaders.filter = !state.loaders.filter;
                return draft;
            }
            case types.Connections.CALCULATION_LOADING_TOGGLE:{
                draft.loaders.calculation = !state.loaders.calculation;
                return draft;
            }
            case types.Connections.REDUX_CONNECTIONS_CALCULATE:
                draft.connectionsData = action.value;
                return draft;
            default:
                return state;
        }
});

export default connectionsReducer;
