import axios from 'axios';
import {WIKI_BASE} from "../constants/links";
import {dthermXBaseAddress} from './baseAddresses';

class ExternalSourceApi {
  static getLatestNews() {
    return axios({ method: 'GET',
      url: `${dthermXBaseAddress()}//sources/news`
    });
  }

  static getCaseStories() {
    return axios({ method: 'GET',
      url: `${dthermXBaseAddress()}/sources/casestories`
    });
  }

  static getCarouselItems() {
    return axios({
      method: 'GET',
      url: `${dthermXBaseAddress()}/sources/carouselItems`
    });
  }

  static getWikiPage(pageId) {
    return axios({ method: 'POST',
      url: `${WIKI_BASE}/graphql`,
      headers: {
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGkiOjEsImdycCI6MSwiaWF0IjoxNTkyNDc3OTI4LCJleHAiOjE2ODcxNTA3MjgsImF1ZCI6InVybjp3aWtpLmpzIiwiaXNzIjoidXJuOndpa2kuanMifQ.LzyQTLuEsLYZqLU-7k_j7g8LONykN8hll6QiZ0bht2lTF87Hq9-IcegpZkLf3NZe400lwRXj10ZmAsoPzH0wE-IKi2xmYn5uVNSXpTCy9QG4_v1_pFfUjG8hbSPqGphfryItidT4_80Z4r97-OhM0RhFBQQOM7Yw-mpKQtoD_groReQAFgqiYqNy0lBPtQD6duL_Hps0gVKIYrF7APFMn7LM6FjlZO-IzB0EFfigsCYDNnONnJzG1fiMhl7ADlySrxc8a_Hr9mn-7F-kmTLH1roQcxlskHY9wz7mo4F2spu_q3B1qJPw0SkerAA4zSAvCzRWE0OzpbtElmFHlKR7Fg'
      },
      data: {
        "operationName":null,
        "variables":{},
        "query":`{\n  pages {\n    single(id: ${pageId}) {\n      path\n      id\n      title\n      render\n    }\n  }\n}\n`
      }
    });
  }

}

export default ExternalSourceApi;
