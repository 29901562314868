import { produce } from 'immer';
import {
  LATEST_NEWS_LOAD,
  LATEST_NEWS_LOAD_SUCCESS,
  LATEST_NEWS_LOAD_ERROR,
  CASE_STORIES_LOAD,
  CASE_STORIES_LOAD_SUCESS,
  WIKI_SAVE_MESSAGES
} from 'constants/actionTypes';
import {
  CAROUSEL_ITEMS_LOAD,
  CAROUSEL_ITEMS_LOAD_ERROR,
  CAROUSEL_ITEMS_LOAD_SUCCESS
} from "../constants/actionTypes";

export const initialState = {
  news: {
    loading: false,
    error: false,
    news: []
  },
  caseStories: {
    loading: false,
    error: false,
    stories: []
  },
  carousel: {
    loading: false,
    error: false,
    items: []
  },
  wiki: {
  }
};

const externalSourcesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LATEST_NEWS_LOAD:
        draft.news.loading = true;
        draft.news.error = false;
        draft.news.news = [];
        break;
      case LATEST_NEWS_LOAD_SUCCESS:
        draft.news.loading = false;
        draft.news.error = false;
        draft.news.news = action.news;
        break;
      case LATEST_NEWS_LOAD_ERROR:
        draft.news.loading = false;
        draft.news.error = action.error;
        draft.news.news = [];
        break;
      case CASE_STORIES_LOAD:
        draft.caseStories.loading = true;
        draft.caseStories.stories = [];
        break;
      case CASE_STORIES_LOAD_SUCESS:
        draft.caseStories.loading = false;
        draft.caseStories.stories = action.stories;
        break;
      case CAROUSEL_ITEMS_LOAD:
        draft.carousel.loading = true;
        draft.carousel.error = false;
        draft.carousel.items = [];
        break;
      case CAROUSEL_ITEMS_LOAD_SUCCESS:
        draft.carousel.loading = false;
        draft.carousel.error = false;
        draft.carousel.items = action.items;
        break;
      case CAROUSEL_ITEMS_LOAD_ERROR:
        draft.carousel.loading = false;
        draft.carousel.error = action.error;
        draft.carousel.items = [];
        break;
      case WIKI_SAVE_MESSAGES:
        draft.wiki = {...draft.wiki, ...action.value};
        break;
    }
  });

export default externalSourcesReducer;
