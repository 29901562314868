/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {matchPath, useLocation, useParams} from 'react-router-dom';
import {useQuery} from "../../utils/helpers";
import {connect, useSelector} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import { Design, Performance, Rating } from 'constants/calculationMethods';
import LoadingPanel from 'components/LoadingPanel/index';
import LoadingIndicator from 'components/LoadingIndicator';
import CalculationInputs from 'components/calculation/inputForms/CalculationInputs';
import * as calculationActions from 'actions/calculationActions';
import { makeSelectUserProfile } from 'selectors/userProfileSelectors';
import { makeSelectCalculationProps, makeSelectIsCalculating } from 'selectors/calculationSelectors';
import injectSaga from 'utils/injectSaga';
import saga from 'sagas/calculationSaga';
import externalSourcesSaga from "sagas/externalSourcesSaga";
import CalculateButton from 'components/calculation/CalculateButton';
import './calculations.scss';
import { AllCalculationTypes, calculationTypesNames } from '../../constants/calculationTypes';
import ResultDispatcher from "../../components/calculation/result/ResultDispather";
import FilterInputs from "../../components/calculation/inputForms/FilterInputs";
import {useTranslation} from "react-i18next";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "../../applicationInsights";
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import {getCurrentBasePath} from "../../utils/routerHelpers";
import { ErrorBoundary } from 'react-error-boundary';
import CalculationResultsFallback from "../../components/Errors/CalculationResultsFallback";
import LogToApplicationInsights from '../../components/Errors/logToApplicationInsights';

const InputsPaper = styled(Paper)(({ theme }) => ({
  elevation: 2,
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(3),
}));

const Calculation = (props) => {
  const [calculationType, setCalculationType] = useState(props.calculationType);
  const [calculationMethod, setCalculationMethod] = useState('');
  const {t} = useTranslation();
  let query = useQuery();
  const location = useLocation();
  let params = useParams();
  const calcProps = useSelector(makeSelectCalculationProps(calculationType));
  const isCalculating = useSelector(makeSelectIsCalculating(calculationType));

  const sessionKey = query.get('sid');
  const exchangers = query.get('hxs');
  const inputParams = {
    heatLoad: query.get('heatload'),
    inletTemperatureSide1: query.get('intemp1'),
    inletTemperatureSide2: query.get('intemp2'),
    outletTemperatureSide1: query.get('outtemp1'),
    outletTemperatureSide2: query.get('outtemp2'),
  };

  useEffect(() => {
    const basePath = getCurrentBasePath(location.pathname);
    const setCalcType = (calcTypes) => {
      calcTypes.forEach(item => {
          if(matchPath(`/${item}`, basePath)){
              setCalculationType(item);
          }
      });
    };

    setCalcType(AllCalculationTypes);
      if(params.calculation){
          switch(params.calculation.toUpperCase()){
              case Rating.toUpperCase():
                  setCalculationMethod(Rating);
                  break;
              case Performance.toUpperCase():
                  setCalculationMethod(Performance);
                  break;
              default:
                  setCalculationMethod(Design);
          }
      } else {
          setCalculationMethod(Design);
      }
  },[location.pathname]);

  useEffect(() => {
      const loadedCalcType = calcProps ? calcProps.type: 'nothingloaded';
      const loadedCalcId = calcProps ? calcProps.id: null;

      if(calculationType && props.userProfile && props.userProfile.userId) {
        if (!calcProps || ((loadedCalcType !== calculationType) || loadedCalcId !== (params.calcId || null))) {
              // console.log('%c \tloadCalculationInputs', 'color:yellow', props, '\n\t\t',calculationType, '\n\t\t', calculationMethod, exchangers, '\n\t\tinputs:',inputParams)
              props.actions.loadCalculationInputs(props.userProfile.userId, calculationType, params.calcId || null, sessionKey, exchangers, inputParams);
        }
      }
  }, [props.userProfile.userId, calculationType, params.calcId, sessionKey, exchangers]);

  // console.log('%c calculation page', 'color:green', props, calcProps, isCalculating, calculationType, calculationMethod);

  if(!calculationType || !props.userProfile.userId) {
      return <LoadingIndicator />;
  }
  // console.log('calculationType', calculationType, 'calculationMethod', calculationMethod, 'calcProps', calcProps, 'isCalculating', isCalculating  );
  return (
    <>
      <h1 className="page-title">
        <div className="d-flex justify-content-between">
          <div />
          <div>{t('Calculation')} - {t(calculationTypesNames[calculationType])}</div>
          <div />
        </div>
      </h1>
      <div className="row">
          <div className="col-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 ">
            <InputsPaper>
              <CalculationInputs calculationType={calculationType} userId={props.userProfile.userId} />
              <FilterInputs calculationType={calculationType} />
              <CalculateButton calculationType={calculationType} calcMethod={calculationMethod} />
            </InputsPaper>
          </div>
          <div className="col-12 col-md-12 col-lg-7 col-xl-7 col-xxl-8 calculation-results">
            <div className="row">
              <div className="col-12 col">
                <ErrorBoundary FallbackComponent={CalculationResultsFallback} onError={LogToApplicationInsights}>
                  <LoadingPanel isLoading={isCalculating || false}>
                  {calcProps &&
                    <ResultDispatcher
                      calculationType={calculationType}/>}
                  </LoadingPanel>
                </ErrorBoundary>
              </div>
          </div>
        </div>
      </div>
    </>);
};

Calculation.propTypes = {
  actions: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  calculationType: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(calculationActions, dispatch),
    };
};

const mapStateToProps = (state) => {
  return {
    userProfile: makeSelectUserProfile()(state),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "calculations", saga: saga });
const withESSaga = injectSaga({ key: "externalSources", saga: externalSourcesSaga });

export default compose(
    withSaga,
    withESSaga,
    withConnect
)(withAITracking(reactPlugin, Calculation, "Calculation"));
