import initialState from './initialState';
import * as types from '../constants/actionTypes';

export default function filtersReducer(state = initialState.filtersData, action) {
    switch (action.type) {
        case types.LOAD_FILTER_DATA:
            return state;
        case types.LOAD_FILTER_DATA_SUCCESS:
            return {...action.filters};
        case types.LOAD_FILTER_DATA_ERROR:
            return state;
        default:
            return state;
    }
}
