import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function unitConversionReducer(state = initialState.unitConversion, action) {
    let newState;
    switch(action.type) {
        case types.CONVERT_FLOW_SUCCESS:
            newState = Object.assign({}, state);
            newState.ValueTo = action.conversion.ValueTo;
            newState.SIValue = action.conversion.SIValue;
            newState.UnitTo = action.conversion.UnitTo;
            return newState;
        default:
            return state;
    }
}