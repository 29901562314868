import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import DropdownTreeSelect from "react-dropdown-tree-select";
import "../../../../node_modules/react-dropdown-tree-select/dist/styles.css";
import './treeSelectStyle.scss';
import clsx from "clsx";
import {IconClose} from 'components/Icons';

export const listToTree = ( array, parent, tree, baseParent ) => {
  tree = typeof tree !== 'undefined' ? tree : [];
  parent = typeof parent !== 'undefined' ? parent : { id: 0 };
  if(baseParent){
    parent = baseParent;
  }

  let children = array.filter(child => child.parentId == parent.id && !parent.isLeaf);

  if( children.length > 0 ){
    if((baseParent && parent.id === baseParent.id) || parent.id == 0 ){
    // if(parent.id == 0 || tree.length === 0 ){
      tree = children;
    } else{
      parent['children'] = children;
    }
    children.forEach((child) => {
      listToTree( array, child );
    });
  }
  return tree;
};

const unCheckAll = (tree) => {
    let nt = tree.map(t => {
      let cs = [];
      if(t.children && t.children.length > 0){
         cs = unCheckAll(t.children);
      }
      return {...t, children: cs, checked: false};
    });
    return nt;
};

const TreeSelect = React.memo((props) => {
  const [treeData, setTreeData] = useState([...props.data]);
  const {className, placeholder, data} = props;

  useEffect(() => {
    // if(data.length > 0) {
      setTreeData(data);
    // }
  }, [data]);

  const onChange = (currentNode, selectedNodes) => {
    if(props.mode === 'single'){
      if(currentNode.isLeaf){
        if(currentNode.checked) {
          props.onChange(currentNode);
        } else {
          props.onChange(null);
        }
      } else {
        props.onChange(null);
      }
    }else{
      props.onChange(selectedNodes.filter(x => x.checked));
    }
  };

   const fluidSearchPredicate = (node, searchTerm) => {
     return node.parentId !== 19 && (node.name.toLowerCase().includes(searchTerm.toLowerCase()) || node.label.toLowerCase().includes(searchTerm.toLowerCase()));
   };

  TreeSelect.displayName = 'TreeSelect';

  const uncheckAll = () => {
    const td = unCheckAll([...treeData]);
    props.onChange([]);
    setTreeData(td);
  };

  // console.log('%ctreeData', 'color:green', props, treeData);

  return (
    <div className={clsx('d-flex', className)}>
      <DropdownTreeSelect tabIndex={props.tabIndex}
        data={treeData}
        clearSearchOnChange={false}
        onChange={onChange}
        className={clsx({'ddltree-single-value':props.mode === 'single'})}
        searchPredicate={props.searchType === "fluid"? fluidSearchPredicate : null}
        keepTreeOnSearch={true}
        inlineSearchInput={props.inlineSearchInput}
        texts={{
          placeholder: placeholder
        }}
        mode={props.mode === "single" ? "radioSelect": "multiSelect"}
        showPartiallySelected={props.showPartiallySelected}
      />
      {props.deleteAllButton && (
      <a className="ts-delete-all d-flex align-items-end" onClick={uncheckAll}><IconClose/></a>
      )}
    </div>
  );
});

TreeSelect.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  showPartiallySelected: PropTypes.bool,
  searchType: PropTypes.string,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  deleteAllButton: PropTypes.bool,
  inlineSearchInput: PropTypes.bool
};

TreeSelect.defaultProps = {
  inlineSearchInput: false
};


export default TreeSelect;
