export const dthermXBaseAddress = () => {
  if (process.env.NODE_ENV === "development"){
    return process.env.API;
  }
  else {
    return `${window.location.origin}/${process.env.API}`;
  }
};

export const datahallBaseAddress = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.DATAHALL;
  }
  else {
    return `${window.location.origin}/${process.env.DATAHALL}`;
  }
};
