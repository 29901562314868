import React, {memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import LoadingPanel from 'components/LoadingPanel/index';
import FilterSelectDropDown from './FilterSelectDropDown';
import ExpandablePanel from "../../common/ExpandablePanel/ExpandablePanel";
import {useTranslation} from "react-i18next";

const FilterSelectPanelBase = ({ calculationType, filterSettings, filters, changeFilters }) => {
    const {t} = useTranslation();

    const handleFilterChange = useCallback((name, selection) => {
        changeFilters(calculationType, name, selection);
    },[changeFilters, calculationType]);

    let showLoader = true;
    if (filterSettings){
        showLoader = false;
    }
    const changeFactories = useCallback((selection) => handleFilterChange("factorySelections", selection), [handleFilterChange]);
    const changeMaterials = useCallback((selection) => handleFilterChange("materialSelections", selection), [handleFilterChange]);
    const changePressures = useCallback((selection) => handleFilterChange("pressureSelections", selection), [handleFilterChange]);
    const changeWarehouses = useCallback((selection) => handleFilterChange("warehouseSelections", selection), [handleFilterChange]);
    const changeOnStockItemsOnly = useCallback((isChecked) => handleFilterChange("showOnStockItemsOnly", isChecked), [handleFilterChange]);

    return (
        <LoadingPanel isLoading={showLoader}>
            {!showLoader &&
              <div className="mt-3">
                <div className="calc-params-table">
                  <div className="row">
                    <div className="col-12"><span className="fw-bold">{t("Filters", 'Filters')}</span></div>
                  </div>
                </div>
                <ExpandablePanel defaultIsExpanded={true}>
                  <div className="calc-params-table">
                    <div className="row">
                      <div className="col-12 col-sm-3 label">{t("Calculation.Filters.Factories", 'Factories')}</div>
                      <div className="col-12 col-sm-9">
                        <FilterSelectDropDown
                          treeData={filters.factories}
                          value={filterSettings.factorySelections}
                          onChange={changeFactories}/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-3 label">{t("Calculation.Filters.Materials", 'Materials')}</div>
                      <div className="col-12 col-sm-9">
                        <FilterSelectDropDown
                          treeData={filters.materials}
                          value={filterSettings.materialSelections}
                          onChange={changeMaterials} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-3 label">{t("Calculation.Filters.Pressures", 'Pressures')}</div>
                      <div className="col-12 col-sm-9">
                        <FilterSelectDropDown
                          treeData={filters.pressures}
                          value={filterSettings.pressureSelections}
                          onChange={changePressures} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-3 label">{t("Calculation.Filters.Warehouses", 'Warehouses')}</div>
                      <div className="col-12 col-sm-9">
                        <div>
                          <FilterSelectDropDown
                            treeData={filters.warehouses}
                            value={filterSettings.warehouseSelections}
                            onChange={changeWarehouses} />
                        </div>
                        {filterSettings.warehouseSelections.some(x => x.isSelected == true) && (
                        <div>
                          <input id="OnStockItemsOnly" type="checkbox" defaultChecked={filterSettings.showOnStockItemsOnly} onChange={e => changeOnStockItemsOnly(e.target.checked)}/>
                          <label htmlFor="OnStockItemsOnly">Show on-stock items only</label>
                        </div>)
                        }
                      </div>
                    </div>
                  </div>
                </ExpandablePanel>
              </div>
        }
        </LoadingPanel>
    );
};

FilterSelectPanelBase.propTypes = {
  calculationType: PropTypes.string.isRequired,
  filterSettings: PropTypes.object,
  filters: PropTypes.shape({
      factories: PropTypes.array,
      materials: PropTypes.array,
      pressures: PropTypes.array,
      warehouses: PropTypes.array,
      defaultSelection: PropTypes.object
  }).isRequired,
  changeFilters: PropTypes.func.isRequired
};


export default memo(FilterSelectPanelBase);

