import { appInsights } from '../../applicationInsights';

const logToApplicationInsights = (error, info) => {
  appInsights.trackException({
    exception: error,
    properties: {...info},
  });
};

export default logToApplicationInsights;
