import axios from 'axios';
import {HEATEXCHANGERS_LOAD, HEATEXCHANGERS_PRODUCTNUMBER, HEATEXCHANGERS_AVAILABLEXPCS} from 'constants/links';
import {dthermXBaseAddress} from './baseAddresses';

class ExchangersApi {

    static getExchangers(userId, modelRestrictions, isCoCurrent, fluid1, fluid2){
        if(!userId || !modelRestrictions){
            return new Promise((resolve, reject) => {
                reject('Input parameters validation error');
            });
        }
        return axios({ method: 'POST',
            url: `${dthermXBaseAddress()}${HEATEXCHANGERS_LOAD}`,
            data: {
                userId, modelRestrictions, isCoCurrent, fluid1, fluid2
            }
        });
    }

    static getProductNumber(xpc){
        if(!xpc){
            return new Promise((resolve, reject) => {
                reject('Input parameters validation error (XPC)');
            });
        }
        return axios({ method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            url: `${dthermXBaseAddress()}${HEATEXCHANGERS_PRODUCTNUMBER}`,
            data: {
                xpc: xpc
            }
        });
    }

    static getAvailableXpcs(request){
        if(!request){
            return new Promise((resolve, reject) => { reject('Input parameters validation error (XPC invalid)');});
        }
        return axios({
            method: 'POST',
            url: `${dthermXBaseAddress()}${HEATEXCHANGERS_AVAILABLEXPCS}`,
            data: request
        });
    }
}

export default ExchangersApi;
