import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectRoot = state => state;
const selectApp = state => state.application;

const selectUserProfileUnits = () =>
  createSelector(
    selectRoot,
    s => s.userProfile.settings.units
  );

const selectWorkingLanguage = () =>
  createSelector(
    selectRoot,
    s => s.userProfile.settings.workingLanguage
  );

const selectIsTranslationAdmin = () =>
  createSelector(
    selectRoot,
    state => state.userProfile.isTranslationAdmin
  );

const selectIsECommerceUser = () =>
  createSelector(
    selectRoot,
    state => {
      let isRedirectedFromCXPortal = localStorage.getItem("isRedirectedFromCXPortal");

      if (isRedirectedFromCXPortal === "true") {
        return true;
      }
      get(state, 'userProfile.isECommerceUser', null);
    }
  );

const selectIsTenderPrintoutEnabled = () =>
  createSelector(
    selectRoot,
    state => state.userProfile.isTenderPrintoutEnabled
  );

const selectAuth = () =>
  createSelector(
    selectApp,
    s => s.auth
  );

const selectRouter = () => createSelector(selectRoot, s => s.router);

export {
  selectUserProfileUnits,
  selectWorkingLanguage,
  selectIsTranslationAdmin,
  selectIsECommerceUser,
  selectIsTenderPrintoutEnabled,
  selectAuth,
  selectRouter
};
