export const UnitSystem = {
  "Metric" : "Metric",
  "Us": "Us"
};

export const UnitGroups = {
  "HeatLoad" : HeatLoad,
  "Temperature": Temperature,
  "TemperatureDifference": TemperatureDifference,
  "Pressure": Pressure,
  "FoulingFactor": FoulingFactor,
  "Flow": Flow,
  "MassFlow": MassFlow,
  "Percentage": Percentage
};

export const HeatLoad = {
    W: 'W',
    kW: 'kW',
    kpm_s:'kpm/s',
    kcal_s:'kcal/s',
    kcal_h:'kcal/h',
    ft_lbf_s:'ft*lbf/s',
    Btu_h:'Btu/h',
    USRT:'USRT'
};

export const Temperature = {
    C: '°C',
    F: '°F',
    K: 'K'
};

export const TemperatureDifference = {
  F: '°F',
  K: 'K'
};

export const Pressure =  {
    kPa : 'kPa',
    Pa : 'Pa',
    bar : 'bar',
    atm : 'atm',
    psi : 'psi',
    mwc : 'mwc'
};

export const FoulingFactor = {
    m_C_kW: 'm²,°C/kW',
    m_h_C_Mcal: 'm²,h,°C/Mcal',
    sqrft_h_F_Btu: 'sqrft,h,°F/Btu'
};

export const Flow = {
    kg_s: 'kg/s',
    kg_h : 'kg/h',
    l_s : 'l/s',
    l_min : 'l/min',
    mmm_h : 'm³/h',
    lb_h : 'lb/h',
    ft_h : 'ft³/h',
    US_gph : 'US gph',
    US_gpm : 'US gpm',
    scfm : 'scfm',
    NI_min : 'NI/min',
    NI_s : 'NI/s',
    Nm_h : 'Nm³/h',
    Nm_min : 'Nm³/min'
};

export const MassFlow = {
  kg_s: 'kg/s',
  kg_h : 'kg/h',
  lb_h : 'lb/h',
};

export const Percentage = {
    percent: '%'
};

export const HeatLoadUnitsPrecisions = {
    [HeatLoad.W]: { name: HeatLoad.W, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    [HeatLoad.kW]: { name: HeatLoad.kW, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    [HeatLoad.kpm_s]: { name: HeatLoad.kpm_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    [HeatLoad.kcal_s]: { name: HeatLoad.kcal_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    [HeatLoad.kcal_h]: { name: HeatLoad.kcal_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1000 },
    [HeatLoad.ft_lbf_s]: { name: HeatLoad.ft_lbf_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1000 },
    [HeatLoad.Btu_h]: { name: HeatLoad.Btu_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1000 },
    [HeatLoad.USRT]: { name: HeatLoad.USRT, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 }
};

export const HeatLoadUnitsPrecisionsArray = [
    { name: HeatLoad.W, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    { name: HeatLoad.kW, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: HeatLoad.kpm_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    { name: HeatLoad.kcal_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: HeatLoad.kcal_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1000 },
    { name: HeatLoad.ft_lbf_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1000 },
    { name: HeatLoad.Btu_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1000 },
    { name: HeatLoad.USRT, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 }
];

export const TemperatureUnitsPrecisions = {
    [Temperature.C]: { name: Temperature.C, signDigits: 5, maxDecimals: 2, minValue: -273, maxValue: Number.maxValue, increment: 1 },
    [Temperature.F]: { name: Temperature.F, signDigits: 5, maxDecimals: 2, minValue: -459, maxValue: Number.maxValue, increment: 1 },
    [Temperature.K]: { name: Temperature.K, signDigits: 5, maxDecimals: 2, minValue: 0, maxValue: Number.maxValue, increment: 1 }
};

export const TemperatureUnitsPrecisionsArray = [
    { name: Temperature.C, signDigits: 5, maxDecimals: 2, minValue: -273, maxValue: Number.maxValue, increment: 1 },
    { name: Temperature.F, signDigits: 5, maxDecimals: 2, minValue: -459, maxValue: Number.maxValue, increment: 1 },
    { name: Temperature.K, signDigits: 5, maxDecimals: 2, minValue: 0, maxValue: Number.maxValue, increment: 1 }
];

export const TemperatureDifferenceUnitsPrecisionsArray = [
  { name: Temperature.F, signDigits: 5, maxDecimals: 2, minValue: -459, maxValue: Number.maxValue, increment: 1 },
  { name: Temperature.K, signDigits: 5, maxDecimals: 2, minValue: 0, maxValue: Number.maxValue, increment: 1 }
];

export const PressureUnitsPrecisions = {
    [Pressure.kPa]: { name: Pressure.kPa, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    [Pressure.Pa]: { name: Pressure.Pa, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    [Pressure.bar]: { name: Pressure.bar, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    [Pressure.atm]: { name: Pressure.atm, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    [Pressure.psi]: { name: Pressure.psi, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    [Pressure.mwc]: { name: Pressure.mwc, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
};

export const PressureUnitsPrecisionsArray = [
    { name: Pressure.kPa, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: Pressure.Pa, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    { name: Pressure.bar, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    { name: Pressure.atm, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    { name: Pressure.psi, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    { name: Pressure.mwc, signDigits: 3, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
];

export const FoulingFactorUnitsPrecision = {
    [FoulingFactor.m_C_kW]: { name: FoulingFactor.m_C_kW, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    [FoulingFactor.m_h_C_Mcal]: { name: FoulingFactor.m_h_C_Mcal, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    [FoulingFactor.sqrft_h_F_Btu]: { name: FoulingFactor.sqrft_h_F_Btu, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 1 },
};

export const FoulingFactorUnitsPrecisionArray = [
    { name: FoulingFactor.m_C_kW, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: FoulingFactor.m_h_C_Mcal, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: FoulingFactor.sqrft_h_F_Btu, signDigits: 4, maxDecimals: 3, minValue: 0, maxValue: Number.maxValue, increment: 1 },
];

export const FlowUnitsPrecision = {
    [Flow.kg_s]: { name: Flow.kg_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    [Flow.kg_h]: { name: Flow.kg_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    [Flow.l_s]: { name: Flow.l_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    [Flow.l_min]: { name: Flow.l_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    [Flow.l_h]: { name: Flow.l_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    [Flow.mmm_h]: { name: Flow.mmm_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    [Flow.lb_h]: { name: Flow.lb_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    [Flow.ft_h]: { name: Flow.ft_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    [Flow.US_gph]: { name: Flow.US_gph, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 10 },
    [Flow.US_gpm]: { name: Flow.US_gpm, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    [Flow.scfm]: { name: Flow.scfm, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    [Flow.NI_min]: { name: Flow.NI_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    [Flow.NI_s]: { name: Flow.NI_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    [Flow.Nm_h]: { name: Flow.Nm_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 10 },
    [Flow.Nm_min]: { name: Flow.Nm_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
};

export const FlowUnitsPrecisionArray = [
    { name: Flow.kg_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    { name: Flow.kg_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    { name: Flow.l_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    { name: Flow.l_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: Flow.l_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: Flow.mmm_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: Flow.lb_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    { name: Flow.ft_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    { name: Flow.US_gph, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 10 },
    { name: Flow.US_gpm, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    { name: Flow.scfm, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 1 },
    { name: Flow.NI_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
    { name: Flow.NI_s, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 100 },
    { name: Flow.Nm_h, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 10 },
    { name: Flow.Nm_min, signDigits: 4, maxDecimals: 4, minValue: 0, maxValue: Number.maxValue, increment: 0.1 },
];

export const PercentageUnitsPrecision = {
    [Percentage.percent]: { name: Percentage.percent, signDigits: 3, maxDecimals: 1, minValue: -1000, maxValue: 1000, increment: 1 }
};

export const PercentageUnitsPrecisionArray = [
    { name: Percentage.percent, signDigits: 3, maxDecimals: 1, minValue: -1000, maxValue: 1000, increment: 1 }
];

export const formatNumber = (value, signDigits, maxDecimals) => {
    let i = 0;
    while (value != null && Math.abs(value / Math.pow(10, i) >= 1)) { i++; }
    if (i >= signDigits) {
        return value.toFixed(0).toString();
    } else {
        const x = ((signDigits - i) > maxDecimals) ? maxDecimals : (signDigits - i);
        return value.toFixed(x).toString();
    }
};

export const formatTemperatureDisplayValue = (amount, unit) => {
    const signDigits = TemperatureUnitsPrecisions[unit].signDigits;
    const maxDecimals = TemperatureUnitsPrecisions[unit].maxDecimals;
    return formatNumber(amount, signDigits, maxDecimals);
};

export const formatHeatLoadDisplayValue = (amount, unit) => {
    const signDigits = HeatLoadUnitsPrecisions[unit].signDigits;
    const maxDecimals = HeatLoadUnitsPrecisions[unit].maxDecimals;
    return formatNumber(amount, signDigits, maxDecimals);
};

export const formatPressureDisplayValue = (amount, unit) => {
    const signDigits = PressureUnitsPrecisions[unit].signDigits;
    const maxDecimals = PressureUnitsPrecisions[unit].maxDecimals;
    return formatNumber(amount, signDigits, maxDecimals);
};

export const formatPercentageDisplayValue = (amount, unit) => {
    const signDigits = PercentageUnitsPrecision[unit].signDigits;
    const maxDecimals = PercentageUnitsPrecision[unit].maxDecimals;
    return formatNumber(amount, signDigits, maxDecimals);
};
