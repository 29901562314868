import React from 'react';
import PropTypes from "prop-types";

const CalculationResultsFallback = (props) => {

  return (
    <div role="alert w-100">
      <p className="text-center">Something went wrong:</p>
      <span className="text-center text-danger">Calculation result </span>
      <span className="text-center text-danger-emphasis">{props.error? props.error.message: ''}</span>
    </div>
  );
};

CalculationResultsFallback.propTypes = {
  error: PropTypes.object,
};

export default CalculationResultsFallback;
