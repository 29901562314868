import * as units from '../constants/units';

export const SinglePhase = {
    inputs: {
        heatLoad: { value: '50', unit: units.HeatLoad.kW, siValue: '50000', name:'HeatLoad' },
        inTemp1: { value: '80', unit: units.Temperature.C, siValue: '80', name:'InTemp1' },
        inTemp2: { value: '30', unit: units.Temperature.C, siValue: '30', name:'InTemp2' },
        outTemp1: { value: '40', unit: units.Temperature.C, siValue: '40', name:'OutTemp1' },
        outTemp2: { value: '60', unit: units.Temperature.C, siValue: '60', name:'OutTemp2' },
        flow1: { value: '', unit: units.Flow.kg_s, siValue: '', name:'Flow1' },
        flow2: { value: '', unit: units.Flow.kg_s, siValue: '', name:'Flow2' },
        mdp1: { value: '50', unit: units.Pressure.kPa, siValue: '50000', name:'MDP1' },
        mdp2: { value: '50', unit: units.Pressure.kPa, siValue: '50000', name:'MDP2' },
        passes: { value: '', unit: null, siValue: null, name:'Passes' },
        os: { value: '', unit: null, siValue: null, name:'OS' },
        ff: { value: null, siValue: null, unit: units.FoulingFactor.m_C_kW, name:'FF'},
        autoPerformance: { value: '0', unit: null, siValue: null, name:'AutoPerformance' },
        plates: { value: '', unit: null, siValue: null, name:'Plates' },
        platesStack1: { value: '', unit: null, siValue: null, name:'PlatesStack1' },
        platesStack2: { value: '', unit: null, siValue: null, name:'PlatesStack2' },
        channel: { value: null, unit: null, siValue: null, name:'Channel' },
        coCurrent: { value: '0', unit: null, siValue: '0', name:'CoCurrent' },
        portSwitch: { value: '0', unit: null, siValue: null, name:'PortSwitch' },
        selectedFluids: {
            side1:{
                categoryType:"LIQ2",
                finalTemp:null,
                id:"53",
                incrementTemp:null,
                initialTemp:null,
                inputValue:null,
                isGas:false,
                max:"0",
                min:"0",
                name:"Water",
                parentID:"3",
                isLeaf:true,
                key:"f53",
                title:"Water",
                pId:3,
                value:"Water"
            },
            side2:{
                categoryType:"LIQ2",
                finalTemp:null,
                id:"53",
                incrementTemp:null,
                initialTemp:null,
                inputValue:null,
                isGas:false,
                max:"0",
                min:"0",
                name:"Water",
                parentID:"3",
                isLeaf:true,
                key:"f53",
                title:"Water",
                pId:3,
                value:"Water"
            }
        },
        exchangers: [
            { key: 1, value:'F1', title: 'B-Types'}
        ]
    },
    filters: {
        factorySelections: [],
        materialSelections: [],
        pressureSelections: [],
        warehouseSelections: [],
        showOnStockItemsOnly: false
    },
    selectedExchanger:{
        connections: {
        }
    }
};
