import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const TextBox = props => {
  return (
    <TextField {...props} />
  );
};

TextBox.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  sx: PropTypes.object
};

export default TextBox;
