import { useLocation, useParams } from 'react-router-dom';

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace('/' + param, ''),
    location.pathname,
  );
};

export const getCurrentBasePath = (locationPath) => {
  const splitted = locationPath.split('/').filter(x => x);
  if(splitted && splitted.length>0){
    return `/${splitted[0]}`;
  }
  return '/';
};
